import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  CircularProgress,
  Fab,
  Drawer,
  Box,
  Tooltip,
  Grid,
  Button,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/esm/Close';
import CloseTwoToneIcon from '@material-ui/icons/esm/CloseTwoTone';
import MuiAlert from '@material-ui/lab/esm/Alert';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  resetMerchantFormData,
  setMerchantFormData
} from '../../reducers/ThemeOptions';
import { fetchFeeSetting } from '../../providers/api';
import FeeSetting from '../FeeSetting';
import MainTable from '../MainTable';
import DateFormat from '../Format/DateFormat';
import { DEFAULT } from '../../constants/dateFormats';
import { useAuthContext } from '../../providers/AuthProvider';

const columns = (isGlobal) => [
  {
    title: isGlobal ? null : <FormattedMessage id="Type" />,
    field: 'merchantId',
    render: ({ merchantId }) => (<>
      {!isGlobal &&
        (merchantId ? <FormattedMessage id="Personal" /> :
          <FormattedMessage id="Global" />)
      }
    </>),
  },
  {
    title: <FormattedMessage id="Activated at" />,
    field: 'activatedAt',
    render: ({ activatedAt }) => (
      <DateFormat date={activatedAt} formatType={DEFAULT} />
    ),
  },
  {
    title: <FormattedMessage id="Expired at" />,
    field: 'expiredAt',
    render: ({ expiredAt }) => (
      <DateFormat date={expiredAt} formatType={DEFAULT} />
    ),
  },
  {
    title: <FormattedMessage id="Payment Method" />,
    field: 'paymentMethod',
    render: ({ paymentMethod }) => (
      <FormattedMessage id={paymentMethod} />
    ),
  },
  {
    title: <FormattedMessage id="Consumer fee" />,
    field: 'consumerFee',
  },
  {
    title: <FormattedMessage id="Merchant fee" />,
    field: 'merchantFee',
  },
  {
    title: <FormattedMessage id="Merchant fixed fee" />,
    field: 'merchantFixedFee',
  },
];

function MerchantEditFee({
  currentMerchantFormData,
  setMerchantFormData,
  resetMerchantFormData
}) {
  const {
    open,
    memberId,
    merchantNumber,
    merchantId,
    isAlertVisible,
    isEditGlobalMerchantFee,
    ...formData
  } = currentMerchantFormData;
  const [currentFeeSettings, setCurrentFeeSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshFees, setRefreshFees] = useState(true);
  const [createFeeSettings, setCreateFeeSettings] = useState(null);
  const [showAllFees, setShowAllFees] = useState(false);

  const { isReadonly } = useAuthContext();
  const intl = useIntl();

  useEffect(() => { }, [currentFeeSettings]);

  useEffect(() => {
    const fetchFeeSettings = async () => {
      setRefreshFees(false);
      setCreateFeeSettings(null);
      setIsLoading(true);
      try {
        const { data } = await fetchFeeSetting(merchantId, showAllFees);

        setCurrentFeeSettings(data);
      } catch (error) {
        console.error('Error fetch fees settings: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (refreshFees) {
      fetchFeeSettings();
    }
  }, [merchantId, refreshFees, showAllFees]);

  const handlerAddFeeSetting = () => {
    const newFeeSetting = {
      activatedAt: new Date(),
      expiredAt: null,
      merchantFee: null,
      merchantFixedFee: null,
      merchantId: merchantId || null,
      paymentMethod: null,
      consumerFee: null,
      feeId: null,
    };
    setCreateFeeSettings(newFeeSetting);
  };

  const handleClose = () => {
    setMerchantFormData({
      isAlertVisible: false,
    });
  };

  const handleChangeShowAllFees = (event) => {
    setShowAllFees(event.target.checked);
    setRefreshFees(true);
  };

  return <>
    {isAlertVisible && (
      <MuiAlert className="alert-wrap" severity="info">
        {memberId ? (
          <FormattedMessage id="Edit successful" />
        ) : (
          <>
            {`Added. Please copy API key: ${formData.token}`}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </MuiAlert>
    )}
    <Drawer
      anchor="right"
      elevation={4}
      open={currentMerchantFormData.open}
      variant="persistent"
      onClose={resetMerchantFormData}
    >
      <Box className="app-header-drawer" flexGrow={1} style={{ height: '100%' }}>
        <Tooltip placement="left" title={<FormattedMessage id="Close" />}>
          <Fab
            className="drawer-close-btn"
            color="secondary"
            onClick={resetMerchantFormData}
          >
            <CloseTwoToneIcon />
          </Fab>
        </Tooltip>
        <PerfectScrollbar>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            minHeight="100%"
            p={4}
          >
            <div className="font-weight-bold font-size-xxl mb-4 text-black">
              {isEditGlobalMerchantFee ?
                <FormattedMessage id="Edit global fee settings" /> :
                <>
                  <FormattedMessage id="Edit fees for" />:&nbsp;
                  {formData.name}
                  {isLoading && (
                    <CircularProgress
                      className="circular-progress"
                      color="secondary"
                      size={18}
                    />
                  )}
                </>
              }
            </div>
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              initial={{ opacity: 0, y: 20 }}
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid container justify="space-between" spacing={4} className={"mb-3"}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={resetMerchantFormData}
                  >
                    <FormattedMessage id="Cancel" />
                  </Button>
                </Grid>

                <Grid item>
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox
                        color="primary"
                        checked={showAllFees}
                        onChange={handleChangeShowAllFees}
                      />
                    } label={intl.formatMessage({ id: 'Show all fees' })} />
                  </FormGroup>
                </Grid>

                <Grid item>
                  <Button
                    disableElevation
                    disabled={isReadonly}
                    color="primary"
                    variant="contained"
                    onClick={handlerAddFeeSetting}
                  >
                    <FormattedMessage id="Add fee settings" />
                  </Button>
                </Grid>
              </Grid>

              {createFeeSettings &&
                <FeeSetting
                  feeSetting={createFeeSettings}
                  setRefreshFees={setRefreshFees}
                  isEditGlobalMerchantFee={isEditGlobalMerchantFee}
                />
              }

              <MainTable
                columns={columns(isEditGlobalMerchantFee)}
                data={currentFeeSettings}
              />
            </motion.div>
          </Box>
        </PerfectScrollbar>
      </Box>
    </Drawer>
  </>;
}

const mapStateToProps = (state) => ({
  currentMerchantFormData: state.ThemeOptions.currentMerchantFormData,
});

const mapDispatchToProps = (dispatch) => ({
  setMerchantFormData: (payload) => dispatch(setMerchantFormData(payload)),
  resetMerchantFormData: () => dispatch(resetMerchantFormData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantEditFee);
