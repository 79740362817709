// Sidebar
export const SET_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_SIDEBAR_SHADOW';
export const SET_SIDEBAR_TOGGLE_MOBILE =
  'THEME_OPTIONS/SET_SIDEBAR_TOGGLE_MOBILE';
export const SET_SIDEBAR_FIXED = 'THEME_OPTIONS/SET_SIDEBAR_FIXED';
export const SET_SIDEBAR_FOOTER = 'THEME_OPTIONS/SET_SIDEBAR_FOOTER';
export const SET_SIDEBAR_TOGGLE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE';
export const SET_SIDEBAR_USERBOX = 'THEME_OPTIONS/SET_SIDEBAR_USERBOX';
export const SET_SIDEBAR_HOVER = 'THEME_OPTIONS/SET_SIDEBAR_HOVER';
export const SET_MERCHANT_FORM_DATA = 'THEME_OPTIONS/SET_MERCHANT_FORM_DATA';
export const RESET_MERCHANT_FORM_DATA =
  'THEME_OPTIONS/RESET_MERCHANT_FORM_DATA';
export const SET_MERCHANT_ALIAS_FORM_DATA = 'THEME_OPTIONS/SET_MERCHANT_ALIAS_FORM_DATA';
export const RESET_MERCHANT_ALIAS_FORM_DATA = 'THEME_OPTIONS/RESET_MERCHANT_ALIAS_FORM_DATA';
export const SET_LANGUAGE = 'THEME_OPTIONS/SET_LANGUAGE';
export const SET_CUSTOMER_INFO = 'THEME_OPTIONS/SET_CUSTOMER_INFO';
export const SET_CUSTOMER_PAYMENT_METHODS = 'THEME_OPTIONS/SET_CUSTOMER_PAYMENT_METHODS';
export const SET_CUSTOMER_DEFAULT_PAYMENT_METHOD = 'THEME_OPTIONS/SET_CUSTOMER_DEFAULT_PAYMENT_METHOD';

export const SET_CREATE_NEW_SESSION = 'THEME_OPTIONS/SET_CREATE_NEW_SESSION';
export const SET_UPDATE_SESSION_DATA = 'THEME_OPTIONS/SET_UPDATE_SESSION_DATA';
export const SET_UPDATE_PREREGISTRATION_DATA = 'THEME_OPTIONS/SET_UPDATE_PREREGISTRATION_DATA';

const initialMerchantData = {
  login: '',
  name: '',
  password: '',
  responseSignature: '',
  isPhoneVerificationRequired: true,
  defaultCallbackOnFailureUrl: '',
  defaultCallbackOnSuccessUrl: '',
  open: false,
  memberId: 0,
  merchantNumber: 0,
  isAlertVisible: false,
  token: '',
  bank: '',
  recipientName: '',
  rut: '',
  accountType: '',
  accountNumber: '',
  email: '',
  isLoading: false,
  paymentMethods: [],
  isEditMerchantFee: null,
  isEditGlobalMerchantFee: null,
  isCreateSessionOnDashboardEnabled: null,
  defaultPaymentMethod: '',
  isSendInvoiceNotificationsEnabled: null,
  isStrictDecisionModel: null,
  creditDecisionModelId: '',
  monthlyAvailableCredit: '',
  dailyAvailableCredit: '',
  merchantFeesSettings: [],
};

const initialMerchantAliasData = {
  login: '',
  name: '',
  password: '',
  isReadonly: false,
  isActive: true,
  open: false,
  isAlertVisible: false,
  aliasNumber: 0,
  isCreateSessionOnDashboardEnabled: false,
  isCreateSessionOnDashboardEnabledMerchant: false,
};

const initialCustomerPaymentMethods = {
  isEtpayEnabled: false,
  isFloidEnabled: false,
  isMachEnabled: false,
  isWebpayEnabled: false,
};

export const setSidebarToggleMobile = (sidebarToggleMobile) => ({
  type: SET_SIDEBAR_TOGGLE_MOBILE,
  sidebarToggleMobile,
});
export const setSidebarToggle = (sidebarToggle) => ({
  type: SET_SIDEBAR_TOGGLE,
  sidebarToggle,
});
export const setSidebarHover = (sidebarHover) => ({
  type: SET_SIDEBAR_HOVER,
  sidebarHover,
});
export const setMerchantFormData = (payload) => ({
  type: SET_MERCHANT_FORM_DATA,
  payload,
});
export const resetMerchantFormData = (payload) => ({
  type: RESET_MERCHANT_FORM_DATA,
  payload,
});

export const setMerchantAliasFormData = (payload) => ({
  type: SET_MERCHANT_ALIAS_FORM_DATA,
  payload,
});
export const resetMerchantAliasFormData = (payload) => ({
  type: RESET_MERCHANT_ALIAS_FORM_DATA,
  payload,
});

export const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});

export const setCustomerInfo = (payload) => ({
  type: SET_CUSTOMER_INFO,
  payload,
});

export const setCustomerPaymentMethods = (payload) => ({
  type: SET_CUSTOMER_PAYMENT_METHODS,
  payload,
});

export const setCustomerDefaultPaymentMethod = (payload) => ({
  type: SET_CUSTOMER_DEFAULT_PAYMENT_METHOD,
  payload,
});

export const setCreateNewSession = (payload) => ({
  type: SET_CREATE_NEW_SESSION,
  payload,
});

export const setUpdateSessionData = (payload) => ({
  type: SET_UPDATE_SESSION_DATA,
  payload,
});

export const setUpdatePreregistrationData = (payload) => ({
  type: SET_UPDATE_PREREGISTRATION_DATA,
  payload,
});

// Page title
export default function reducer(
  state = {
    sidebarToggleMobile: false,
    sidebarToggle: false,
    sidebarHover: false,
    currentMerchantFormData: initialMerchantData,
    currentMerchantAliasFormData: initialMerchantAliasData,
    language: 'es',
    createNewSession: false,

    updateSessionData: 0,
    updatePreregistraationData: 0,

    customerPaymentMethods: initialCustomerPaymentMethods,
  },
  action,
) {
  switch (action.type) {
    // Sidebar
    case SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.sidebarToggleMobile,
      };
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.sidebarToggle,
      };
    case SET_SIDEBAR_HOVER:
      return {
        ...state,
        sidebarHover: action.sidebarHover,
      };
    case SET_MERCHANT_FORM_DATA:
      return {
        ...state,
        currentMerchantFormData: {
          ...state.currentMerchantFormData,
          ...action.payload,
        },
      };
    case RESET_MERCHANT_FORM_DATA:
      return {
        ...state,
        currentMerchantFormData: initialMerchantData,
      };
    case SET_MERCHANT_ALIAS_FORM_DATA:
      return {
        ...state,
        currentMerchantAliasFormData: {
          ...state.currentMerchantAliasFormData,
          ...action.payload,
        },
      };
    case RESET_MERCHANT_ALIAS_FORM_DATA:
      return {
        ...state,
        currentMerchantAliasFormData: initialMerchantAliasData,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload,
      };
    case SET_CUSTOMER_PAYMENT_METHODS:
      return {
        ...state,
        customerPaymentMethods: action.payload,
      };
    case SET_CUSTOMER_DEFAULT_PAYMENT_METHOD:
      return {
        ...state,
        customerDefaultPaymentMethod: action.payload,
      };
    case SET_CREATE_NEW_SESSION:
      return {
        ...state,
        createNewSession: action.payload,
      };
    case SET_UPDATE_SESSION_DATA:
      return {
        ...state,
        updateSessionData: action.payload,
      };
    case SET_UPDATE_PREREGISTRATION_DATA:
      return {
        ...state,
        updatePreregistrationData: action.payload,
      };
    default:
      break;
  }
  return state;
}
